<template>
  <NuxtLink
    :to="`/restaurants/${restaurant.slug}${anchor ? anchor : ''}`"
    :class="(restaurant.is_working && restaurant.is_online) ? 'bg-white' : 'bg-#e0e6e4'"
    class="flex gap-3 lg:gap-4.5 shadow-[0_2px_8px_0] lg:shadow-[0_2px_8px_0] shadow-black/10 ring-2 ring-transparent ring-inset hover:shadow-[0_6px_20px_0] hover:shadow-black/15 hover:ring-firmennyy/50 transition-shadow p-3 lg:p-4 rounded-4 lg:rounded-5 items-center"
  >
    <div class="shrink-0 relative">
      <div class="flex gap-1 flex-wrap absolute inset-1.25 lg:inset-1.5 items-start">
        <!--        <Tag-->
        <!--          v-if="true"-->
        <!--          size="sm"-->
        <!--        >-->
        <!--          Лучшая цена-->
        <!--        </Tag>-->
      </div>
      <div itemscope itemtype="http://schema.org/ImageObject" class="relative">
        <NuxtImg
          itemprop="contentUrl"
          :class="imageClass ? imageClass : 'rounded-2 lg:rounded-2.5 size-33 lg:size-42 object-center object-cover'"
          :src="restaurant.img_path"
          alt="restaurant"
          placeholder
          loading="lazy"
        />
        <div
          v-show="!restaurant.is_working"
          class="absolute inset-0 bg-black/50 blur"
        ></div>
        <span
          v-if="!restaurant.is_working"
          class="p-1 rounded-1 absolute bottom-1 right-1 text-#fff text-3 bg-black"
        >Ресторан закрыт</span>
        <div
          v-if="isOnlySelfCheckout"
          class="absolute top-0 right-0 bg-yellow-500 text-white text-sm px-2 py-1 rounded"
        >
          Только Самовывоз
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1.75 lg:gap-2.5">
      <p class="text-tekstovyy text-4.25 lg:text-5.5 font-500 vertical-trim-height">{{ restaurant.title }}</p>
      <p class="text-4 lg:text-4.5">
        {{
          /* @ts-ignore */
          restaurant.additional_param.time_to_deliver
        }} мин — от {{ restaurant.additional_param.min_order_sum }} ₽
      </p>
      <p class="text-seryy text-3.25 lg:text-4">{{ restaurant.additional_param.short_description }}</p>
      <div class="grid grid-cols-[auto_1fr] gap-y-1.5 gap-x-3.5 lg:gap-x-4 text-3 lg:text-3.75 text-seryy">
        <p class="flex items-center gap-1.25 lg:gap-1.5">
          <span class="text-3.5 lg:text-4.5 text-firmennyy shrink-0 i-custom:star"></span>
          {{ restaurant.average_score }}
        </p>
        <p class="flex items-center gap-1.25 lg:gap-1.5">
          <span class="text-3.5 lg:text-4.5 text-firmennyy shrink-0 i-custom:message"></span>
          {{ restaurant.count_feedbacks ? `${restaurant.count_feedbacks} отзыва` : 'Нет отзывов' }}
        </p>
        <p class="flex items-center gap-1.25 lg:gap-1.5">
          <span class="text-3.5 lg:text-4.5 text-firmennyy shrink-0 i-custom:money"></span>
          <span
            :class="{/* @ts-ignore - исправить типы  */'text-seryy': restaurant.additional_param.avg_check_level }"
          >{{ restaurant.additional_param.avg_check }}₽</span>
        </p>
        <p
          class="flex gap-1.25 lg:gap-1.5"
          :class="{ 'items-center': restaurant.is_working || !restaurant.is_online }"
        >
          <span class="text-3.5 lg:text-4.5 text-firmennyy shrink-0 i-custom:timelapse"></span>
          <span
            class="[vertical-trim:Cap_height]"
            :class="{ 'text-#E15435': !restaurant.is_working }"
          >
            {{ (restaurant.is_working) ? `${restaurantInfo.workingHours}` : `закрыто \n ${restaurantInfo.workingHours}` }}
          </span>
        </p>
      </div>
      <p
        v-if="true"
        class="text-seryy text-3.25 -mt-0.875 max-lg:hidden"
      >
        Бесплатная доставка
      </p>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { RestaurantType } from '~/composables/restaurant/types/RestaurantType';

const props = defineProps<{
	restaurant: RestaurantType;
	imageClass?: string;
  anchor?: string;
}>();

const isOnlySelfCheckout = computed(() => props.restaurant.order_delivery_types.length === 1 && props.restaurant.order_delivery_types[0].order_delivery_type.id === 2);
const restaurantInfo = computed(() => {
  const dayOfWeek = new Date().getDay();
  const rest = props.restaurant.working_hours.find((t) => t.day_of_week.id === dayOfWeek);
  let time: string[] = [];
  if (!rest?.opening_time) {
    return {
      workingHours: '',
      isOpen: false,
      isOnline: false
    };
  }

  if (rest) {
    time = [rest.opening_time, rest?.closing_time].map((time) => {
      const parts = time.split(':');
      parts.pop();
      return parts.join(':');
    });
  }

  return {
    workingHours: `${time[0]}-${time[1]}`,
    isOpen: isCurrentTimeLessThan(rest?.opening_time, rest.closing_time),
    isOnline: props.restaurant.is_online === 1
  };
});

function isCurrentTimeLessThan (openingTime: string, closingTime: string) {
  const now = new Date();
  const sakhalinTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Sakhalin' }));
  const opening = new Date(sakhalinTime);
  const closing = new Date(sakhalinTime);
  const [openHours, openMinutes, openSeconds] = openingTime.split(':').map(Number);
  const [closeHours, closeMinutes, closeSeconds] = closingTime.split(':').map(Number);
  opening.setHours(openHours, openMinutes, openSeconds, 0);
  closing.setHours(closeHours, closeMinutes, closeSeconds, 0);
  return sakhalinTime >= opening && sakhalinTime <= closing;
}
</script>
